import React from "react";
import { Modal, Header } from "semantic-ui-react";
import "./styles.css";

const ModalComponent = ({
  open,
  onClose,
  size,
  headerIcon,
  headerContent,
  modalContent,
  actions,
  modalError
}) => {
  return (
    <Modal size={size} dimmer={"inverted"} open={open} onClose={onClose}>
      <Header icon={headerIcon} content={headerContent} />
      <Modal.Content>
        {modalError ? (
          <div className="ui negative message">
            <i className="exclamation triangle icon" />
            <p>{modalError}</p>
          </div>
        ) : (
          <p>{modalContent}</p>
        )}
      </Modal.Content>
      <Modal.Actions>{actions()}</Modal.Actions>
    </Modal>
  );
};

export default ModalComponent;
