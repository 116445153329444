import React, { Component } from "react";
import { Dropdown, Loader, Pagination } from "semantic-ui-react";
import { inject, observer } from "mobx-react";

import Service from "../../services";

import DataTable from "../../components/DataTable/DataTable";
import Search from "../../components/Search/Search";

import "./styles.css";

const categories = [
  {
    text: "Todos",
    value: "Todos"
  },
  {
    text: "Ácidos",
    value: "ácidos"
  },
  {
    text: "Capilar",
    value: "capilar"
  },
  {
    text: "Corporales",
    value: "corporales"
  },
  {
    text: "Faciales",
    value: "faciales"
  },
  {
    text: "Masculinos",
    value: "masculinos"
  }
];

const pageSize = 20;

class Products extends Component {
  constructor() {
    super();
    const userRegion =
      window.localStorage.getItem("user") &&
      JSON.parse(window.localStorage.getItem("user")).region;

    if (!userRegion) {
      Service.logout();
      window.location.href = "/";
    }
    this.state = {
      activePage: 1,
      totalPages: 0,
      loading: true,
      data: [],
      filteredData: [],
      userRegion
    };
  }

  fetchData = async () => {
    const products = await Service.getProducts(0, -1);

    //this.test(products);

    this.setState({
      data: products.data,
      filteredData: products.data.slice(0, pageSize),
      loading: false,
      totalPages: Math.ceil(products.total / pageSize)
    });
  };

  componentDidMount() {
    this.fetchData(this.state.activePage, true);
  }

  // filterData = value => {
  //   if (value === "Todos") {
  //     this.setState({ filteredData: this.state.data });
  //   } else {
  //     const filteredData = this.state.data.filter(
  //       v => v["category.name"] === value
  //     );
  //     this.setState({ filteredData });
  //   }
  // };

  // changeFilter = (e, data) => {
  //   this.filterData(data.value);
  // };

  renderFilter = () => (
    <div className="filters">
      <label>Rubro:</label>
      <Dropdown
        selection
        options={categories}
        defaultValue={"Todos"}
        onChange={this.changeFilter}
      />
    </div>
  );

  handlePaginationChange = (e, { activePage }) => {
    window.scrollTo(0, 0);
    this.setState(state => ({
      activePage,
      filteredData: state.data.slice(
        (activePage - 1) * pageSize,
        (activePage - 1) * pageSize + pageSize
      )
    }));
  };

  search = value => {
    const { data } = this.state;
    let totalPages = 0;
    let filtered = [];
    if (value !== "") {
      filtered = data.filter(e => {
        const regex = RegExp(value, "gi");
        return regex.test(e.name);
      });
      totalPages = Math.ceil(filtered.length / pageSize);
    } else {
      filtered = data.slice(0, pageSize);
      totalPages = Math.ceil(data.length / pageSize);
    }

    if (filtered.length > 0) {
      this.setState({
        activePage: 1,
        totalPages,
        filteredData: filtered
      });
    }
  };

  renderContent = () => {
    return (
      <div>
        <div className="filters">
          <Search onSearch={this.search} />
        </div>
        <DataTable
          data={this.state.filteredData}
          userRegion={this.state.userRegion}
        />
        <div className="paginationWrapper">
          <Pagination
            firstItem={null}
            lastItem={null}
            activePage={this.state.activePage}
            onPageChange={this.handlePaginationChange}
            totalPages={this.state.totalPages}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Loader active>Cargando</Loader>
        ) : (
          this.renderContent()
        )}
      </div>
    );
  }
}

//export default Products;
export default inject("stores")(observer(Products));
