import presentations from "./presentations";
export const filterPresentation = (data, userLocation) => {
  const a = [];
  presentations.forEach(v => {
    if (v.indexOf(userLocation) > 0 && data[v] > 0) {
      a.push({ presentation: v.split("_")[0], value: data[v] });
    }
  });
  return a;
};
