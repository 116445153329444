import React, { Component } from "react";

import './styles.css';

class Search extends Component {
  state = {
    value: ""
  };
  handleChange = event => {
    const { value } = this.state;
    if (value.length >= 3) {
      this.props.onSearch(value);
    }
    this.setState({ value: event.target.value });
  };
  clear = () => {
    this.props.onSearch("");
    this.setState({ value: "" });
  };
  render() {
    const { value } = this.state;

    return (
      <div className="ui large icon input">
        <input
          placeholder="Buscar..."
          type="text"
          value={value}
          onChange={this.handleChange}
        />
        {value ? (
          <i className="cancel link icon" onClick={this.clear} />
        ) : (
          <i className="search icon" />
        )}
      </div>
    );
  }
}

export default Search;
