import React from "react";
import { inject, observer } from "mobx-react";
import { Dropdown } from 'semantic-ui-react'
import { NavLink, withRouter } from "react-router-dom";
import service from "../../services";

import logo from "../../images/logo.png";

import "./Header.css";




const hamburgerOnclick = () => {
  const hamburger = document.querySelector(".hamburger");
  const header = document.querySelector("header");
  const mobileMenu = document.querySelector(".mobile-menu");
  hamburger.classList.toggle("is-active");
  mobileMenu.classList.toggle("active");
  header.classList.toggle("menu-opened");
};

class Header extends React.Component {

 renderDropDown = () => (
  <Dropdown text='MI CUENTA'>
    <Dropdown.Menu>
      <Dropdown.Item>            
          <NavLink
              className="link"
              to={`${this.props.match.url}/profile`}
              activeClassName="active"
            >
              Mis Datos
            </NavLink>
            </Dropdown.Item>

    </Dropdown.Menu>
  </Dropdown>
)

  logout = () => {
    service.logout();
    this.props.history.push("/");
  };
  componentDidMount() {
    const mobileMenuItems = document.querySelectorAll(".mobile-menu .link");
    mobileMenuItems.forEach(element => {
      element.onclick = () => hamburgerOnclick();
    });
  }
  render() {
    return (
      <header className="header">
        <div>
          <img src={logo} className="header-logo" alt="logo" />
          <div className="menu-desktop desktop">
            <NavLink
              className="link"
              to={`${this.props.match.url}/products`}
              activeClassName="active"
            >
              PRODUCTOS
            </NavLink>
            <NavLink
              className="link"
              to={`${this.props.match.url}/order`}
              activeClassName="active"
            >
              COTIZACIÓN
              {!!this.props.stores.Order.totalItems && (
                <div className="floating ui circular mini label">
                  {this.props.stores.Order.totalItems}
                </div>
              )}
            </NavLink>
            <NavLink
              className="link"
              to={`${this.props.match.url}/priceList`}
              activeClassName="active"
            >
              LISTADO DE PRECIOS
            </NavLink>
            { this.renderDropDown() }
          </div>
        </div>

        <div>
          <button className="logout desktop" onClick={this.logout}>
            <i className="logout icon" />
            <span className="desktop">SALIR</span>
          </button>
          <button
            className="hamburger hamburger--slider mobile"
            type="button"
            onClick={hamburgerOnclick}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
        <div className="mobile-menu">
          <div className="items">
            <NavLink
              className="link"
              to={`${this.props.match.url}/products`}
              activeClassName="active"
            >
              PRODUCTOS
            </NavLink>
            <NavLink
              className="link"
              to={`${this.props.match.url}/order`}
              activeClassName="active"
            >
              COTIZACIÓN
              {!!this.props.stores.Order.totalItems && (
                <div className="floating ui circular mini label">
                  {this.props.stores.Order.totalItems}
                </div>
              )}
            </NavLink>
            <NavLink
              className="link"
              to={`${this.props.match.url}/priceList`}
              activeClassName="active"
            >
              LISTADO DE PRECIOS
            </NavLink>
            <NavLink
              className="link"
              to={`${this.props.match.url}/profile`}
              activeClassName="active"
            >
              Mis Datos
            </NavLink>
            <div className="link" onClick={this.logout}>
              SALIR
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(inject("stores")(observer(Header)));
