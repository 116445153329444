import React from "react";
import { Loader } from "semantic-ui-react";

import Service from "../../services";

import logo from "../../images/logo.png";

class VerifyRegistration extends React.Component {
  state = {
    loading: true,
    verified: false
  };
  async componentDidMount() {
    const response = await Service.verifyRegistration(
      this.props.match.params.token
    );

    if (response.isVerified) {
      this.setState({ loading: false, verified: true });
    } else {
      this.setState({ loading: false, verified: false });
    }
  }

  renderMessage = () => (
    <div className="content">
      {this.state.verified
        ? "Gracias por confirmar el email"
        : "No se ha podido verificar el email"}
    </div>
  );
  render() {
    return (
      <div className="loginWrapper">
        <div className="ui middle aligned center aligned grid">
          <div className="column">
            <img src={logo} alt="logo" />
            <h2 className="ui header">
              {this.state.loading ? (
                <Loader active>Verificando...</Loader>
              ) : (
                this.renderMessage()
              )}
            </h2>
            <a className="ui button teal" href="/login">
              Ingresar
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default VerifyRegistration;
