import React, { Component } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { inject, observer } from "mobx-react";
import { Button } from "semantic-ui-react";
import "./PriceList.css";
import Service from "../../services";
import presentations from "../../utils/presentations";

const months = {
  1: "Enero",
  2: "Febrero",
  3: "Marzo",
  4: "Abril",
  5: "Mayo",
  6: "Junio",
  7: "Julio",
  8: "Agosto",
  9: "Septiembre",
  10: "Octubre",
  11: "Noviembre",
  12: "Diciembre"
};

// const categories = [
//   {
//     id: 1,
//     name: "suero de ácidos"
//   },
//   {
//     id: 2,
//     name: "capilar"
//   },
//   {
//     id: 3,
//     name: "corporales"
//   },
//   {
//     id: 4,
//     name: "faciales"
//   },
//   {
//     id: 5,
//     name: "masculinos"
//   },
//   {
//     id: 6,
//     name: "set"
//   },
//   {
//     id: 7,
//     name: "limpieza facial"
//   },
//   {
//     id: 8,
//     name: "solar"
//   }
// ];

function removeByCategory(arr, category) {
  const filtered = [];
  const withCategory = [];

  arr.forEach(function(ele) {
    ele.categoryId == category ? withCategory.push(ele) : filtered.push(ele);
  });

  return [filtered, withCategory];
}

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const getPresentation = (product, userRegion) => {
  return presentations.filter(p => p.indexOf(userRegion) > 0 && product[p] > 0);
};

class PriceList extends Component {
  state = {
    products: []
  };

  fetchData = async () => {
    const response = await Service.getProducts(0, -1);
    const products = [...response.data];
    const [notLimpiezaFacial, catLimpiezaFacial] = removeByCategory(
      products,
      7
    );
    const [notFaciales, catFaciales] = removeByCategory(notLimpiezaFacial, 4);
    const [notAcidos, catAcidos] = removeByCategory(notFaciales, 1);
    const [notCorporales, catCorporales] = removeByCategory(notAcidos, 3);

    const alfa = notCorporales.sort((a, b) => {
      if (a["category.name"] > b["category.name"]) return 1;
      if (a["category.name"] < b["category.name"]) return -1;
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
    });

    const sorted = [
      ...catLimpiezaFacial,
      ...catFaciales,
      ...catAcidos,
      ...catCorporales,
      ...alfa
    ];

    this.setState({
      products: sorted,
      loading: false
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  downloadPdf = options => {
    const { suggested } = options;
    const { products } = this.state;
    const userRegion =
      window.localStorage.getItem("user") &&
      JSON.parse(window.localStorage.getItem("user")).region;

    const date = `${
      months[new Date().getMonth() + 1]
    } ${new Date().getFullYear()}`;
    const list = {
      capital: "CABA y GBA",
      interior: "ARG"
    };

    let title = `Listado de Precios Profesionales ${
      list[userRegion]
    } - ${date}.`;

    if (suggested) {
      title = `Listado de Precios Sugeridos al Público - ${date}`;
    }

    const docDefinition = {
      header: { text: `MIRA DROR - ${title}`, fontSize: 9, italics: true },
      pageSize: "A4",

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [10, 20, 10, 10],
      content: [
        // {
        //   text: title,
        //   fontSize: 16,
        //   margin: [0, 20, 0, 0]
        // }
      ],
      styles: {
        head: {
          color: [128, 128, 128],
          bold: true,
          fontSize: 9,
          margin: [5, 4, 0, 0]
        },
        value: {
          fontSize: 10,
          margin: [5, 2, 0, 4]
        }
      }
    };

    products.forEach((product, index) => {
      const name = product.name;
      const category = product["category.name"];
      const presentations = getPresentation(product, userRegion);
      const bgColor = index % 2 ? "#eeeeee" : null;
      const row = [];
      const table = {
        layout: "noBorders",
        width: "100%",
        table: {
          widths: [350],
          body: []
        }
      };

      row.push({
        fillColor: bgColor,
        stack: [
          { text: category, style: "head" },
          { text: name, style: "value" }
        ]
      });

      presentations.forEach(p => {
        table.table.widths.push(40);
        const price = suggested ? product[p] * 1.7 : product[p];

        row.push({
          fillColor: bgColor,
          stack: [
            { text: `${p.split("_")[0]}`, style: "head" },
            { text: `$ ${parseInt(price, 10)}`, style: "value" }
          ]
        });
      });
      table.table.widths[table.table.widths.length - 1] = "*";
      table.table.body.push(row);

      if (suggested && product.suggested_to_public) {
        docDefinition.content.push(table);
      }

      if (!suggested) {
        docDefinition.content.push(table);
      }
    });

    pdfMake.createPdf(docDefinition).download(`Miradror ${title}`);
  };
  render() {
    return (
      <div className="app-container priceList">
        <div>
          <Button
            default
            icon="download"
            labelPosition="right"
            content="DESCARGAR LISTADO DE PRECIOS"
            onClick={this.downloadPdf}
          />
        </div>
        <div>
          <Button
            default
            icon="download"
            labelPosition="right"
            content="DESCARGAR LISTADO DE PRECIOS SUGERIDOS AL PÚBLICO"
            onClick={() => this.downloadPdf({ suggested: true })}
          />
        </div>
      </div>
    );
  }
}

export default inject("stores")(observer(PriceList));
