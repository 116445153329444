import React, { Component } from "react";
import math from "mathjs";
import { inject, observer } from "mobx-react";

class Footer extends Component {
  renderTotalWithDiscount = () => {
    if (this.props.user.discount) {
      const total = this.props.stores.Order.total;
      const totalSets = this.props.stores.Order.totalSets;
      const { discount } = this.props.user;
      const totalWithoutSets = total - totalSets;
      const totalWithDiscount =
        totalWithoutSets -
        math.round((discount * totalWithoutSets) / 100) +
        totalSets;
      return (
        <tr>
          <th>Total</th>
          <th className="no-border" />
          <th className="no-border" />
          <th className="no-border" />
          <th className="no-border">{`$${totalWithDiscount}`}</th>
        </tr>
      );
    }
    return null;
  };
  renderDiscount = () => {
    if (this.props.user.discount) {
      return (
        <tr>
          <th className="cell-green">Descuento</th>
          <th className="no-border" />
          <th className="no-border" />
          <th className="no-border" />
          <th className="cell-green no-border">{`${
            this.props.user.discount
          }%`}</th>
        </tr>
      );
    }
    return null;
  };
  render() {
    return (
      <tfoot>
        <tr>
          <th />
          <th className="no-border" />
          <th className="no-border" />
          <th className="no-border" />
          <th className="no-border">${this.props.stores.Order.total}</th>
        </tr>
        {this.renderDiscount()}
        {this.renderTotalWithDiscount()}
      </tfoot>
    );
  }
}

export default inject("stores")(observer(Footer));
