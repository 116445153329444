import React, { Component } from "react";

import { Route } from "react-router-dom";
import Products from "../Products/Products";
import Order from "../Order/Order";
import Header from "../../components/Header/Header";
import PriceList from "../PriceList/PriceList";
import Profile from "../Profile/Profile"

class Dashboard extends Component {
  render() {
    return (
      <div>
        <Header />

        <Route path={`${this.props.match.url}/products`} component={Products} />
        <Route path={`${this.props.match.url}/order`} component={Order} />
        <Route path={`${this.props.match.url}/profile`} component={Profile} />

        <Route
          path={`${this.props.match.url}/priceList`}
          component={PriceList}
        />
      </div>
    );
  }
}

export default Dashboard;
