export default [
  "10g_capital",
  "10g_interior",
  "33g_capital",
  "33g_interior",
  "60g_capital",
  "60g_interior",
  "70g_capital",
  "70g_interior",
  "125g_capital",
  "125g_interior",
  "150g_capital",
  "150g_interior",
  "200g_capital",
  "200g_interior",
  "250g_capital",
  "250g_interior",
  "300g_capital",
  "300g_interior",
  "500g_capital",
  "500g_interior",
  "set_interior",
  "set_capital"
];
