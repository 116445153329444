/* global ga */

const feathers = require("@feathersjs/client");
const rest = feathers.rest;
const auth = feathers.authentication;
const axios = require("axios");

class Service {
  constructor() {
    const feathersApp = feathers();

    const restClient = rest(process.env.REACT_APP_API_URL);
    feathersApp.configure(restClient.axios(axios));
    feathersApp.configure(auth({ storage: window.localStorage }));
    
    this.client = feathersApp;
    // this.client
    //   .configure(rest(process.env.REACT_APP_API_URL).fetch(window.fetch))
    //   .configure(auth({ storage: window.localStorage }));
    this.user = {};
  }

  reAuthenticate = () => this.client.authentication.reAuthenticate()

  auth = async credentials => {
    try {
      let response;
      if (!credentials || !credentials.email || !credentials.password) {
        response = await this.client.authenticate();
      } else {
        const payload = Object.assign({ strategy: "local" }, credentials);
        response = await this.client.authenticate(payload);
      }
      const verify = await this.client.passport.verifyJWT(response.accessToken);
      const user = await this.client.service("users").get(verify.userId);
      this.client.set("user", user);
      window.localStorage.setItem(
        "user",
        JSON.stringify(this.client.get("user"))
      );
      return this.client.get("user");
    } catch (error) {
      return error;
    }
  };

  logout = () => {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("order");
    this.client.logout();
  };

  errorHandler = error => {
    ga("send", "exception", {
      exDescription: JSON.stringify(error)
    });
    if (error.code === 401) {
      window.location.href = "/";
    } else {
      return error;
    }
  };

  getUsers = async () => {
    const service = this.client.service("clients");
    try {
      const response = await service.find();
      return response;
    } catch (error) {
      return this.errorHandler(error);
    }
  };

  getUser = async id => {
    const service = this.client.service("clients");
    try {
      const response = await service.get(id);
      return response;
    } catch (error) {
      return this.errorHandler(error);
    }
  };

  createOrder = async (discount, observations, total) => {
    const user = await this.auth();
    if (user.email) {
      const service = this.client.service("orders");
      try {
        const response = await service.create({
          userId: this.client.get("user").id,
          discount,
          observations,
          total
        });
        return response;
      } catch (error) {
        return this.errorHandler(error);
      }
    } else {
      return { error: 403 };
    }
  };

  createOrderDetail = async orders => {
    const service = this.client.service("orders-detail");

    try {
      const response = await service.create(orders);
      return response;
    } catch (error) {
      return this.errorHandler(error);
    }
  };

  createUser = async data => {
    const service = this.client.service("users");
    try {
      const response = await service.create(Object.assign(data));
      return response;
    } catch (error) {
      return this.errorHandler(error);
    }
  };

  updateUser = async data => {
    let payload = {};
    if(data.region) {
      payload.region = data.region;
    } else {
      payload = data;
    }

    const service = this.client.service("users");
    const user = JSON.parse(localStorage.getItem("user"))
    
    try {
      const response = await service.patch(user.id, payload);
      localStorage.setItem("user", JSON.stringify({...user, ...response}))
      return response;
    } catch (error) {
      console.error(error)
      return this.errorHandler(error);
    }
  };

  updatePrice = async percent => {
    const service = this.client.service("products");
    try {
      const response = await service.patch(null, { percent });
      return response;
    } catch (error) {
      return this.errorHandler(error);
    }
  };

  getProducts = async (skip = 0, limit = 10) => {
    // const service = this.client.service("products");
    // try {
    //   const response = await service.find({
    //     query: {
    //       $limit: -1,
    //       $sort: {
    //         name: 1
    //       }
    //     }
    //   });
    //   return response;
    // } catch (error) {
    //   return this.errorHandler(error);
    // }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/products`,
        {
          params: {
            $skip: skip,
            $limit: limit,
            "$sort[name]": 1
          }
        }
      );
      if (limit === -1) {
        return {
          data: response.data,
          total: response.data.length
        };
      }

      return response.data;
    } catch (error) {
      return this.errorHandler(error);
    }
  };

  getProduct = async id => {
    const service = this.client.service("products");
    try {
      const response = await service.get(id);
      return response;
    } catch (error) {
      return this.errorHandler(error);
    }
  };

  updateProduct = async data => {
    const service = this.client.service("products");
    try {
      const response = await service.update(data.id, data);
      return response;
    } catch (error) {
      return this.errorHandler(error);
    }
  };

  resetPassword = async data => {
    const payload = {
      action: "sendResetPwd",
      value: { email: data.email }
    };
    const service = this.client.service("authManagement");

    try {
      const response = await service.create(payload);
      return response;
    } catch (error) {
      return this.errorHandler(error);
    }
  };

  updatePassword = async (password, token) => {
    const payload = {
      action: "resetPwdLong",
      value: {
        token,
        password
      }
    };
    const service = this.client.service("authManagement");

    try {
      const response = await service.create(payload);
      return response;
    } catch (error) {
      return this.errorHandler(error);
    }
  };

  verifyRegistration = async token => {
    const payload = {
      action: "verifySignupLong",
      value: token
    };

    const service = this.client.service("authManagement");

    try {
      const response = await service.create(payload);
      return response;
    } catch (error) {
      return this.errorHandler(error);
    }
  };
}

const service = new Service();
export default service;
