import React from "react";
import { Button } from "semantic-ui-react";
import ProductDialog from "../ProductDialog/ProductDialog";
import presentations from "../../utils/presentations";
import noProduct from "../../images/producto-no-disponible.jpg";
import "./styles.css";

const renderPrice = (presentationField, v) => (
  <div key={`${presentationField}-${v}`} className="product-price-wrapper">
    <span className="product-price-presentation">
      {presentationField.split("_")[0]}
    </span>
    <span className="product-price-value">${v}</span>
  </div>
);

const renderPresentation = (data, userRegion) => {
  let component = [];

  presentations.forEach(presentationField => {
    if (
      presentationField.indexOf(userRegion) > 0 &&
      data[presentationField] > 0
    ) {
      component.push(renderPrice(presentationField, data[presentationField]));
    }
  });
  return <div className="product-prices-container">{component}</div>;
};

const renderProduct = (data, handleClickOpen, userRegion) => (
  <div>
    <div className="product-description">
      <div className="category-name">{data["category.name"]}</div>
      <div className="product-name" onClick={() => handleClickOpen(data)}>
        {data.name}
      </div>
      <div className="product-description-text">
        {data.description && data.description !== "0"
          ? data.description
          : "Una formula para cada piel."}
      </div>
    </div>
    {renderPresentation(data, userRegion)}
  </div>
);

const renderImage = (data, handleClickOpen) => (
  <img
    onClick={() => handleClickOpen(data)}
    className="image-product"
    src={
      data.image
        ? `${process.env.REACT_APP_API_URL}/products/${data.image}`
        : noProduct
    }
    alt={data.name}
  />
);

class Table extends React.Component {
  state = {
    open: false,
    product: {}
  };

  renderRow = data => (
    <div key={data.id} className="datarow">
      <div className="imageWrapper">
        {renderImage(data, this.handleClickOpen)}
      </div>
      <div className="productWrapper">
        {renderProduct(data, this.handleClickOpen, this.props.userRegion)}
      </div>
      {/* <div className="middle aligned one wide column ">
        <div className="">{this.renderButton(data)}</div>
      </div> */}
    </div>
  );

  handleClickOpen = data => {
    this.setState({ open: true, product: data });
  };

  handleClose = () => {
    this.setState({ open: false, product: {} });
  };

  renderButton = data => (
    <Button
      className="btnOrder"
      color="teal"
      size="mini"
      onClick={() => this.handleClickOpen(data)}
    >
      Encargar
    </Button>
  );

  render() {
    return (
      <div>
        <div className="app-container table">
          {this.props.data.map(e => this.renderRow(e))}
        </div>
        <ProductDialog
          open={this.state.open}
          handleClose={this.handleClose}
          product={this.state.product}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          userRegion={this.props.userRegion}
        />
      </div>
    );
  }
}

export default Table;
