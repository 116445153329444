import { observable, action, toJS, autorun } from "mobx";

const Orders = observable(
  {
    order: localStorage.getItem("miradror-order")
      ? JSON.parse(localStorage.getItem("miradror-order"))
      : [],
    setItem(newItem) {
      this.order.push(newItem);
    },
    removeItem(index) {
      const dummy = toJS(this.order).slice();
      dummy.splice(index, 1);
      this.order = dummy;
    },
    updateItem(index, data) {
      const dummy = toJS(this.order).slice();
      dummy[index] = data;
      this.order = dummy;
    },
    clean() {
      this.order = [];
    },

    get totalItems() {
      let count = 0;
      this.order.forEach(e => (count = count + e.quantity));
      return count;
    },
    get total() {
      let total = 0;
      this.order.forEach(e => {
        total = total + e.price * e.quantity;
      });
      return total;
    },
    get totalSets() {
      let total = 0;
      this.order.forEach(e => {
        if (e.category === "set") {
          total = total + e.price * e.quantity;
        }
      });
      return total;
    }
  },
  {
    setItem: action,
    removeItem: action,
    updateItem: action,
    clean: action
  }
);

autorun(() => {
  const order = toJS(Orders.order);
  localStorage.setItem("miradror-order", JSON.stringify(order));
});
export default Orders;
