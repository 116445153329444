import React from "react";
import { Redirect } from "react-router-dom";
import Service from "../../services";
import logo from "../../images/logo.png";
import { Dropdown, Modal, Button, Header } from "semantic-ui-react";
import "./styles.css";

const getCredentials = () => {
  const user = {
    email: document.querySelector('[name="email"]').value,
    password: document.querySelector('[name="password"]').value
  };

  return user;
};

class Login extends React.Component {
  state = {
    redirectToDashboard: false,
    register: false,
    email: "",
    password: "",
    passwordConfirm: "",
    name: "",
    city: "",
    phone: "",
    address: "",
    region: "",
    error: "",
    sending: false,
    modalOpen: false
  };

  backToList = () => {
    this.setState({ modalOpen: false, register: false, error: "" });
  };

  renderModal = open => (
    <Modal size={"mini"} dimmer={"inverted"} open={open}>
      <Header icon="user" content="Registración completada" />
      <Modal.Content>
        <p>
          Por favor confirme su email. Hemos enviado un correo electrónico con
          las instrucciones para finalizar el registro
        </p>
        <p>
          Si el correo no llega en 15 minutos, por favor, revise la carpeta spam
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          icon="checkmark"
          labelPosition="right"
          content="Ir al Login"
          onClick={this.backToList}
        />
      </Modal.Actions>
    </Modal>
  );
  handleLogin = async () => {
    const credentials = getCredentials();
    if (!credentials.email || !credentials.password) {
      this.setState({ error: "Ingresar Email y Contraseña" });
    } else {
      this.setState({ sending: true }, async () => {
        const response = await this.login({ redirect: false });
        if (response.code === 401) {
          this.setState({ error: "Datos Incorrectos", sending: false });
        }
        if (response.email) this.setState({ redirectToDashboard: true });
      });
    }
  };

  login = async ({ redirect }) => {
    const credentials = getCredentials();
    const response = await Service.auth(credentials);
    if (response.email && redirect) {
      this.setState({ redirectToDashboard: true });
    } else {
      return response;
    }
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  componentDidMount() {
    this.login({ redirect: true });
  }

  changeForm = () => {
    this.setState({ register: !this.state.register });
  };

  register = async () => {
    const data = this.state;
    this.setState({ sending: true }, async () => {
      const response = await Service.createUser(data);
      const newState = {};
      console.log(response);

      if (response.errors && response.errors[0].validatorKey === "not_unique") {
        newState.error = "El email ya se encuentra registrado";
      } else if (response.id) {
        newState.modalOpen = true;
      } else {
        newState.error = "Ha ocurrido un error. Intentelo nuevamente";
      }
      this.setState(Object.assign({}, newState, { sending: false }));
    });
  };

  handleRegister = () => {
    let errorMsg = null;
    const {
      email,
      password,
      passwordConfirm,
      name,
      city,
      phone,
      address,
      region
    } = this.state;

    if (
      !email ||
      !password ||
      !passwordConfirm ||
      !name ||
      !city ||
      !phone ||
      !address ||
      !region
    ) {
      errorMsg = "Completar todos los campos";
    }

    if (!errorMsg && password.length < 6) {
      errorMsg = "Las contraseña debe tener al menos 6 caracteres";
    }

    if (!errorMsg && password !== passwordConfirm) {
      errorMsg = "Las contraseñas no coinciden";
    }

    if (!errorMsg) {
      this.register();
    } else {
      this.setState({ error: errorMsg });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.register) {
      this.handleRegister();
    } else {
      this.handleLogin();
    }
  };

  handleDropdownChange = (e, { value }) => {
    this.setState({ region: value });
  };

  renderLogin = () => {
    return (
      <div>
        <div className="ui raised secondary segment">
          <div className="field">
            <div className="ui left icon input">
              <i className="user icon" />
              <input type="text" name="email" placeholder="E-mail" />
            </div>
          </div>
          <div className="field">
            <div className="ui left icon input">
              <i className="lock icon" />
              <input type="password" name="password" placeholder="Contraseña" />
            </div>
          </div>
          <button
            type="submit"
            className={`ui fluid large teal submit ${this.state.sending &&
              "loading"} button`}
          >
            Ingresar
          </button>
          <br />
          <div onClick={this.changeForm} className="ui fluid large button">
            Registrarse
          </div>
          <div className="rememberPass">
            <a href="/resetPassword">Olvidé mi contraseña</a>
          </div>
        </div>

        <div className={`ui error message ${this.state.error && "visible"}`}>
          {this.state.error}
        </div>
      </div>
    );
  };

  renderRegister = () => {
    return (
      <div>
        <div className="ui raised secondary segment left aligned">
          <div className="required field">
            <label>Email</label>
            <input
              type="text"
              name="email"
              onChange={this.handleInputChange}
              value={this.state.email}
            />
          </div>

          <div className="required field">
            <label>Contraseña</label>
            <input
              type="password"
              name="password"
              value={this.state.password}
              onChange={this.handleInputChange}
            />
          </div>

          <div className="required field">
            <label>Confirmar Contraseña</label>
            <input
              type="password"
              name="passwordConfirm"
              value={this.state.passwordConfirm}
              onChange={this.handleInputChange}
            />
          </div>

          <div className="required field">
            <label htmlFor="">Distribuidora</label>
            <input
              type="text"
              name="name"
              onChange={this.handleInputChange}
              value={this.state.name}
            />
          </div>

          <div className="required field">
            <label htmlFor="">Teléfono</label>
            <input
              type="text"
              name="phone"
              onChange={this.handleInputChange}
              value={this.state.phone}
            />
          </div>

          <div className="required field">
            <label htmlFor="">Dirección</label>
            <input
              type="text"
              name="address"
              onChange={this.handleInputChange}
              value={this.state.address}
            />
          </div>

          <div className="required field">
            <label htmlFor="">Localidad</label>
            <input
              type="text"
              name="city"
              onChange={this.handleInputChange}
              value={this.state.city}
            />
          </div>

          <div className="required field">
            <label htmlFor="">Lista de Precios</label>

            <Dropdown
              onChange={this.handleDropdownChange}
              options={[
                { key: "capital", text: "CABA y GBA", value: "capital" },
                { key: "interior", text: "Interior", value: "interior" }
              ]}
              placeholder="Seleccionar"
              selection
              value={this.state.region}
              className="dropdown"
            />
          </div>
          <button
            type="submit"
            className={`ui fluid large teal submit ${this.state.sending &&
              "loading"} button`}
          >
            Ingresar
          </button>
          <br />
          <div onClick={this.changeForm} className="ui fluid large button">
            Cancelar
          </div>
        </div>
        <div className={`ui error message ${this.state.error && "visible"}`}>
          {this.state.error}
        </div>
      </div>
    );
  };

  render() {
    const { redirectToDashboard } = this.state;

    if (redirectToDashboard) {
      return <Redirect to={"/dashboard/products"} />;
    }

    return (
      <div className="loginWrapper">
        <div className="ui middle aligned center aligned grid">
          <div className="column">
            <img src={logo} alt="logo" />

            <h2 className="ui header">
              <div className="content">Ingrese sus datos</div>
            </h2>
            <form className="ui form loginForm" onSubmit={this.handleSubmit}>
              {this.state.register ? this.renderRegister() : this.renderLogin()}
            </form>
          </div>
        </div>
        {this.renderModal(this.state.modalOpen)}
      </div>
    );
  }
}

export default Login;
