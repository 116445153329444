import React from "react";
import { Dropdown, Modal, Button, Input, Form, Image } from "semantic-ui-react";
import { inject, observer } from "mobx-react";
import "./style.css";
import noProduct from "../../images/producto-no-disponible.jpg";

import { filterPresentation } from "../../utils/product";

class ProductDialog extends React.Component {
  state = {
    presentation: "",
    quantity: 0
  };
  handleChangeInput = (e, { value }) => this.setState({ quantity: value });
  handleChangeSelect = (e, { value }) => this.setState({ presentation: value });

  validate = () => {
    if (this.state.presentation && this.state.quantity > 0) {
      this.props.stores.Order.setItem({
        id: this.props.product.id,
        presentation: this.state.presentation,
        quantity: parseInt(this.state.quantity, 10),
        price: parseInt(
          this.props.product[
            `${this.state.presentation}_${this.props.userRegion}`
          ],
          10
        ),
        category: this.props.product["category.name"]
      });
      this.setState({ presentation: "" });
      this.props.handleClose();
    }
  };

  handleClose = () => {
    this.setState({ presentation: "" });
    this.props.handleClose();
  };

  render() {
    const productOptions = () =>
      filterPresentation(this.props.product, this.props.userRegion).map(v => ({
        value: v.presentation,
        text: `${v.presentation}: $${v.value}`
      }));

    return (
      <Modal
        dimmer="inverted"
        open={this.props.open}
        onClose={this.handleClose}
      >
        <Modal.Header>
          <span className="modal-product-title">{this.props.product.name}</span>
        </Modal.Header>
        <Modal.Content image>
          <Image
            wrapped
            className="productDialogImageWrapper"
            size="medium"
            src={
              this.props.product.image
                ? `${process.env.REACT_APP_API_URL}/products/${
                    this.props.product.image
                  }`
                : noProduct
            }
          />
          <Modal.Description>
            <div className="product-dialog-description">
              {this.props.product.descripcion || "Una formula para cada piel."}
            </div>
            <Form>
              <Form.Field>
                <label htmlFor="">Presentación</label>
                <Dropdown
                  onChange={this.handleChangeSelect}
                  placeholder="Selecionar presentación"
                  value={this.state.presentation}
                  fluid
                  selection
                  options={productOptions()}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="">Cantidad</label>
                <Input
                  fluid
                  type="number"
                  onChange={this.handleChangeInput}
                  placeholder="Ingresar la cantidad"
                />
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} basic>
            Cancelar
          </Button>
          <Button onClick={this.validate} color="green">
            Encargar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default inject("stores")(observer(ProductDialog));
