import React, {useState, useEffect} from 'react'
import Service from '../../services';
import {  Button } from "semantic-ui-react";

import Modal from "../../components/Modal/Modal";

export default function Profile(){
    const [error, setError] = useState("")
    const [userName, setUserName] = useState("")
    const [userPhone, setUserPhone] = useState("")
    const [userAddress, setUserAddress] = useState("")
    const [sending, setSending] = useState(false)
    const [modalOpened, setModalOpened ] = useState(false)

    async function updateUser() {
      if(!sending) {
        setSending(true)
        const response = await Service.updateUser({
          name: userName,
          phone: userPhone,
          address: userAddress
        })
        if(response.name === userName) {
          setModalOpened(true)
        }
        setSending(false)

      }
    }

    function handleSubmit(e){
      e.preventDefault();

        if(!userName || !userPhone || !userAddress) {
            setError("Debe completar todos los campos")
        } else {
            updateUser();
        }
    }

    function handleInputChange( event) {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
      
      switch (name) {
          case 'name':
              setUserName(value)
              break;
              case 'phone':
                setUserPhone(value)
                break;
                case 'address':
                    setUserAddress(value)
                    break;
            
          default:
              break;
      }

    };

    useEffect(()=>{
        const user = window.localStorage.getItem('user')
        if(user) {
            const userData = JSON.parse(user) 
            setUserName(userData.name)
            setUserPhone(userData.phone)
            setUserAddress(userData.address)

        }
    }, [])

    const modalActions = () => (
      <Button
        positive
        icon="checkmark"
        labelPosition="right"
        content="OK"
        onClick={() => setModalOpened(false)}
      />
    );

    return (
        <div className="formWrapper">
        <div className="ui middle aligned center aligned grid">
          <div className="column">
            
            <h2 className="ui header">
              <div className="content">Mis Datos</div>
            </h2>
            <div className="ui raised secondary segment">
              <form className="ui form loginForm" onSubmit={handleSubmit}>
        

                <div className="required field">
                  <label>Nombre</label>
                  <input
                    name="name"
                    value={userName}
                    onChange={handleInputChange}

                  />
                </div>

                <div className="required field">
                  <label>Direccion</label>
                  <input
                    name="address"
                    value={userAddress}
              onChange={handleInputChange}

                  />
                </div>

                <div className="required field">
                  <label>Teléfono</label>
                  <input
                    name="phone"
                    value={userPhone}
                    onChange={handleInputChange}

                  />
                </div>
                <button className="ui button fluid teal" type="submit">
                  Actualizar
                </button>
              </form>
              {error && (
                <div className="ui error message visible">
                  {error}
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          open={modalOpened}
          onClose={() => setModalOpened(false)}
          size="mini"
          headerIcon="user outline"
          headerContent={"Perfecto"}
          modalContent="Información actualizada"
    
          actions={modalActions}
        />
      </div>
    )
}