import React from "react";

import Row from "./Row";

const RowList = props =>
  props.order.map((e, i) => {
    return (
      <Row
        key={i}
        index={i}
        order={e}
        product={props.products.find(p => p.id === e.id)}
        region={props.user.region}
      />
    );
  });

export default RowList;
