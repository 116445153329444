import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from "mobx-react";

import "semantic-ui-css/semantic.min.css";
import { version } from "../package.json";
import "./App.css";
import Login from "./screens/Login/Login";
import Dashboard from "./screens/Dashboard/Dashboard";
import ResetPassword from "./screens/ResetPassword/ResetPassword";
import ForgotPassword from "./screens/ResetPassword/ForgotPassword";
import VerifyRegistration from "./screens/VerifyRegistration/VerifyRegistration";
import PriceList from "./screens/PriceList/PriceList";

import stores from "./stores";

class App extends Component {
  render() {
    return (
      <Provider stores={stores}>
        <Router>
          <div>
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            <Route
              exact
              path="/login/verify/:token"
              component={VerifyRegistration}
            />
            <Route
              exact
              path="/login/reset/:token"
              component={ForgotPassword}
            />

            <Route path="/resetPassword" component={ResetPassword} />

            <Route path="/priceList" component={PriceList} />
            <Route path="/dashboard" component={Dashboard} />
            <footer>version {version}</footer>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
