import React from "react";
import math from "mathjs";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";

class Row extends React.Component {
  removeItem() {
    this.props.stores.Order.removeItem(this.props.index);
  }

  changeValue = v => {
    const order = this.props.stores.Order.order[this.props.index];
    const quantity = order.quantity;
    const newQuantity = quantity + v;

    if (newQuantity > 0) {
      this.props.stores.Order.updateItem(
        this.props.index,
        Object.assign({}, toJS(order), { quantity: newQuantity })
      );
    }
  };

  render() {
    const { product } = this.props;
    const order = this.props.stores.Order.order[this.props.index];
    const subtotal = math.round(order.quantity * order.price, 3);

    return (
      <tr>
        <td>{product.name}</td>
        <td>{order.presentation}</td>
        <td>
          <div className="quantityWrapper">
            <div>{order.quantity}</div>
            <div className="buttons">
              <i
                onClick={() => this.changeValue(1)}
                className="plus circle link icon"
              />
              <i
                onClick={() => this.changeValue(-1)}
                className="minus circle link icon"
              />
            </div>
          </div>
        </td>
        <td>${order.price}</td>
        <td>
          <div className="subtotalWrapper">
            <div>${subtotal}</div>
            <div onClick={() => this.removeItem()}>
              <i className="trash alternate outline link icon" />
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

export default inject("stores")(observer(Row));
