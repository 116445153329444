/* global ga */
import React from "react";
import { inject, observer } from "mobx-react";
import { Loader, Button, TextArea, Dimmer } from "semantic-ui-react";
import Service from "../../services";
import Modal from "../../components/Modal/Modal";
import RowList from "./RowList";
import Footer from "./Footer";

import "./styles.css";

class OrderDetail extends React.Component {
  state = {
    loading: true,
    products: [],
    user: {},
    modalError: "",
    modalOpen: false,
    observations: "",
    total: 0,
    headerContent: "Cotización enviada",
    observationsLength: 0
  };

  // this is now being emitted back to the parent from the child component
  deleteItem = indexToDelete => {
    this.setState(({ list }) => ({
      list: list.filter((toDo, index) => index !== indexToDelete)
    }));
  };

  fetchData = async () => {
    const user = await Service.auth();

    if (user.error) {
      Service.logout();
      window.location.href = "/";
      return;
    }

    const response = await Service.getProducts(0, -1);
    const products = response.data;

    // let total = 0;

    // const order = currentOrder.map(element => {
    //   const productId = Object.keys(element)[0];
    //   // eslint-disable-next-line
    //   const product = response.find(p => p.id == productId);
    //   const presentation = Object.keys(element[productId])[0];
    //   const quantity = parseInt(element[productId][presentation], 10);
    //   const price = parseFloat(product[`${presentation}_${this.userRegion}`]);
    //   const subtotal = math.round(quantity * price, 3);
    //   total = total + subtotal;

    //   return Object.assign({}, product, {
    //     order: {
    //       presentation,
    //       quantity,
    //       price,
    //       subtotal
    //     }
    //   });
    // });

    this.setState({
      products,
      loading: false,
      user
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  onChange = (key, subtotal) => {
    this.setState({ total: subtotal });
  };

  postData = async () => {
    let modalError = "";
    let headerContent = "Cotización enviada";
    let observations = this.state.observations;
    this.setState({
      loading: true
    });

    const order = await Service.createOrder(
      this.state.user.discount || 0,
      observations,
      this.props.stores.Order.order.total
    );

    if (order.code === 403) {
      ga("send", "exception", {
        exDescription: "NOT AUTHENTICATED"
      });
      return this.props.history.push("/login");
    }

    if (order.code >= 400) {
      modalError = `Ha ocurrido un error. Vuelva a intentarlo.\n Service.createOrder ${
        order.message
      }`;
      ga("send", "exception", {
        exDescription: `SERVICE CREATE ORDER:  ${order.message}`
      });
      headerContent = "Atención";
    }

    const storedOrder = this.props.stores.Order.order;
    const { region } = this.state.user;
    const userOrder = storedOrder.map(e => {
      const price = this.state.products.find(p => p.id === e.id)[
        `${e.presentation}_${region}`
      ];
      return {
        userId: order.userId,
        productId: e.id,
        orderId: order.id,
        presentation: e.presentation,
        price: parseInt(price, 10),
        quantity: e.quantity
      };
    });

    const orderDetail = await Service.createOrderDetail(userOrder);

    if (orderDetail.code) {
      modalError = `Ha ocurrido un error. Vuelva a intentarlo.\n Service.createOrderDetail ${
        orderDetail.message
      }`;
      ga("send", "exception", {
        exDescription: `SERVICE CREATE ORDER DETAIL:  ${orderDetail.message}`
      });
      headerContent = "Atención";
    } else {
      if (orderDetail.length && orderDetail[0].orderId !== order.id) {
        ga("send", "exception", {
          exDescription: `SERVICE VALIDATE ORDER: Data is not consistent`
        });
        modalError = `Ha ocurrido un error. Vuelva a intentarlo.\n Data is not consistent`;
        headerContent = "Atención";
      }
    }

    if (!modalError) {
      observations = "";
      this.cleanOrder();
    }

    this.setState({
      headerContent,
      modalError,
      loading: false,
      modalOpen: true,
      observations
    });
  };

  validate = () => {
    if (
      this.props.stores.Order.order.length > 0 &&
      this.state.observationsLength < 1000
    ) {
      this.postData();
    }
  };

  cleanOrder = () => {
    this.props.stores.Order.clean();
  };

  handleChange = event => {
    const observations = event.target.value;
    const observationsLength = observations.length;

    this.setState({ observations, observationsLength });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  backToList = () => {
    this.props.history.push("/dashboard/products");
  };

  modalActions = () => (
    <Button
      positive
      icon="checkmark"
      labelPosition="right"
      content="Volver al listado"
      onClick={this.backToList}
    />
  );

  renderLoader = () => (
    <Dimmer active inverted>
      <Loader active>Cargando</Loader>
    </Dimmer>
  );

  render() {
    const { observationsLength } = this.state;
    return (
      <div className="app-container">
        <div className="ui left aligned container">
          <h4 className="ui dividing header">Cotización</h4>
          <table className="ui celled table">
            <thead>
              <tr>
                <th>Producto</th>
                <th>Presentación</th>
                <th>Cantidad</th>
                <th>Costo</th>
                <th className="subtotalColumn">Subtotal</th>
              </tr>
            </thead>
            <tbody>
              {!!this.state.products.length && (
                <RowList
                  products={this.state.products}
                  user={this.state.user}
                  order={this.props.stores.Order.order}
                />
              )}
            </tbody>
            <Footer user={this.state.user} />
          </table>
          <div className="ui form">
            <div className="field">
              <label>
                Observaciones <span>(máximo 1000 caracteres)</span>
              </label>
              {observationsLength > 999 && (
                <p className="danger">
                  Estas superando el maximo permitido. Por favor, trate de
                  escribir menos de 1000 caracteres.
                </p>
              )}
              <TextArea
                placeholder="Comentarios, pedidos, etc"
                value={this.state.observations}
                onChange={this.handleChange}
              />
            </div>
            <div className="ui right aligned container">
              <Button onClick={this.cleanOrder} basic>
                Eliminar Productos
              </Button>
              <Button onClick={this.validate} color="green">
                Enviar Cotización
              </Button>
            </div>
          </div>
          {this.state.loading && this.renderLoader()}
        </div>
        <Modal
          open={this.state.modalOpen}
          onClose={this.closeModal}
          size="mini"
          headerIcon="clipboard outline"
          headerContent={this.state.headerContent}
          modalContent="Nos pondremos en contacto a la brevedad."
          modalError={this.state.modalError}
          actions={this.modalActions}
        />
      </div>
    );
  }
}

export default inject("stores")(observer(OrderDetail));
