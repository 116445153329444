import React from "react";
import { Modal, Button, Header } from "semantic-ui-react";

import Service from "../../services";
import logo from "../../images/logo.png";

class ResetPassword extends React.Component {
  state = {
    modalOpen: false,
    email: ""
  };

  renderModal = open => (
    <Modal size={"mini"} dimmer={"inverted"} open={open}>
      <Header icon="lock" content="Restablecer Contraseña" />
      <Modal.Content>
        <p>
          Hemos enviado un correo electrónico con un enlace. Siga las
          instrucciones en el correo para finalizar de restablecer su contraseña
        </p>
        <p>
          Si el correo no llega en 15 minutos, por favor, revise la carpeta spam
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          icon="checkmark"
          labelPosition="right"
          content="OK"
          onClick={() => (window.location.href = "/login")}
        />
      </Modal.Actions>
    </Modal>
  );

  handleChange = event => {
    this.setState({ email: event.target.value });
  };

  handleSubmit = e => {
    const { email } = this.state;
    e.preventDefault();
    if (email) {
      this.setState({ modalOpen: true });
      Service.resetPassword({ email });
    }
  };

  render() {
    return (
      <div className="loginWrapper">
        <div className="ui middle aligned center aligned grid">
          <div className="column">
            <img src={logo} alt="logo" />

            <h2 className="ui header">
              <div className="content">Ingresar Email</div>
            </h2>
            <div className="ui raised secondary segment">
              <form className="ui form loginForm" onSubmit={this.handleSubmit}>
                <div className="content">
                  <div className="field">
                    <div className="ui left icon input">
                      <i className="user icon" />
                      <input
                        type="text"
                        name="email"
                        placeholder="E-mail"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <button className="ui button fluid teal" type="submit">
                    Restablecer
                  </button>
                  <br />
                  <a className="ui button fluid " href="/login">
                    Cancelar
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
        {this.renderModal(this.state.modalOpen)}
      </div>
    );
  }
}

export default ResetPassword;
