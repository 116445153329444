import React from "react";
import { Modal, Button, Header } from "semantic-ui-react";

import Service from "../../services";

import logo from "../../images/logo.png";

class ResetPassword extends React.Component {
  state = {
    modalOpen: false,
    password: "",
    passwordConfirm: "",
    error: "",
    msg: ""
  };

  renderModal = open => (
    <Modal size={"mini"} dimmer={"inverted"} open={open}>
      <Header icon="lock" content="Restablecer Contraseña" />
      <Modal.Content>
        <p>{this.state.msg}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          icon="checkmark"
          labelPosition="right"
          content="OK"
          onClick={() => (window.location.href = "/login")}
        />
      </Modal.Actions>
    </Modal>
  );

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  post = async () => {
    let msg = "";
    const { password } = this.state;
    const response = await Service.updatePassword(
      password,
      this.props.match.params.token
    );
    console.log(response);
    if (response.code === 400) {
      msg = "Ha ocurrido un error, vuelva a intentar.";
    } else {
      msg = "Contraseña reestablecida";
    }
    this.setState({ modalOpen: true, msg });
  };

  handleSubmit = e => {
    e.preventDefault();
    let errorMsg = null;
    const { password, passwordConfirm } = this.state;

    if (!password || !passwordConfirm) {
      errorMsg = "Completar todos los campos";
    }

    if (!errorMsg && password.length < 6) {
      errorMsg = "Las contraseña debe tener al menos 6 caracteres";
    }

    if (!errorMsg && password !== passwordConfirm) {
      errorMsg = "Las contraseñas no coinciden";
    }

    if (!errorMsg) {
      this.post();
    } else {
      this.setState({ error: errorMsg });
    }
  };

  render() {
    return (
      <div className="loginWrapper">
        <div className="ui middle aligned center aligned grid">
          <div className="column">
            <img src={logo} alt="logo" />
            <h2 className="ui header">
              <div className="content">Ingresar nueva contraseña</div>
            </h2>
            <div className="ui raised secondary segment">
              <form className="ui form loginForm" onSubmit={this.handleSubmit}>
                <div className="required field">
                  <label>Contraseña</label>
                  <input
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleInputChange}
                  />
                </div>

                <div className="required field">
                  <label>Confirmar Contraseña</label>
                  <input
                    type="password"
                    name="passwordConfirm"
                    value={this.state.passwordConfirm}
                    onChange={this.handleInputChange}
                  />
                </div>
                <button className="ui button fluid teal" type="submit">
                  Confirmar
                </button>
                <br />
                <a className="ui button fluid " href="/login">
                  Cancelar
                </a>
              </form>
              {this.state.error && (
                <div className="ui error message visible">
                  {this.state.error}
                </div>
              )}
            </div>
          </div>
        </div>
        {this.renderModal(this.state.modalOpen)}
      </div>
    );
  }
}

export default ResetPassword;
